@import '../../Styles.scss';
body:has(.PublicContentVaultLink) {
    background: rgb(10, 96, 173);
    background: linear-gradient(135deg, rgba(10, 96, 173, 1) 0%, rgba(20, 45, 85, 1) 50%);
    .dx-loadindicator-segment-inner {
        border-color: var(--backgroundColor) var(--backgroundColor) transparent !important;
    }
    .dx-scrollview-content:has(.login-form) {
        .header {
            padding-top: 40px !important;
        }
        .title {
            font-size: 32px;
        }
        .description {
            display: none;
        }
    }
    .dx-scrollview-content:has(.forgot-password-form) {
        .description {
            font-size: medium;
            line-height: 18px;
        }
    }
    .reviewDescription {
        font-weight: unset !important;
    }
    .dx-field-item-label-location-top {
        padding: 16px 0px 3px;
    }
}
